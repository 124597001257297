import React, {useEffect, useState} from "react";
import {getDatabase, onValue, ref} from "firebase/database";
import {firebaseApp} from "./Service/Connection";
import * as core from "./core";

export function PublicMachine({publicCode, machineId}) {

    // const [snapshotMachine, loadingMachine, errorMachine] = ListenFirebaseDb(`Public/${publicCode}/Machine/Machine/${machineId}`)
    // const [snapshotMachineState, loadingMachineState, errorMachineState] = ListenFirebaseDb(`Public/${publicCode}/Machine/State/Machine/State/${machineId}`)
    // const [snapshotMachineStats, loadingMachineStats, errorMachineStats] = ListenFirebaseDb(`Public/${publicCode}/Stats/Machine/MachineTopic/${machineId}`)
    // const [snapshotTopics, loadingTopics, errorTopics] = ListenFirebaseDb(`Machine/MachineTopic`)

    const [dataMachine, setDataMachine] = useState(null)
    const [dataMachineState, setDataMachineState] = useState(null)
    const [dataMachineStats, setDataMachineStats] = useState(null)
    const [dataTopics, setDataTopics] = useState(null)

    // Fetch DataMachine
    useEffect(() => {
        const db = getDatabase(firebaseApp);
        const dbRef = ref(db, `Public/${publicCode}/Machine/Machine/${machineId}`);
        onValue(dbRef, (snapshot) => {
            // console.debug("DataMachine: onValue: snapshot.val(): ", snapshot.val())
            if (snapshot.val() === null) {
                return
            }
            let machine = snapshot.val()
            // Add machineId to machine
            machine.Id = machineId
            setDataMachine(machine)
        }, error => {
            console.error(error)
        })
    }, [publicCode, machineId])

    // Fetch DataMachineState
    useEffect(() => {
        const db = getDatabase(firebaseApp);
        const dbRef = ref(db, `Public/${publicCode}/Machine/State/Machine/State/${machineId}`);
        onValue(dbRef, (snapshot) => {
            // console.debug("DataMachineState: onValue: snapshot.val(): ", snapshot.val())
            if (snapshot.val() === null) {
                return
            }
            setDataMachineState(snapshot.val())
        }, error => {
            console.error(error)
        })
    }, [publicCode, machineId])

    // Fetch DataMachineStats
    useEffect(() => {
        const db = getDatabase(firebaseApp);
        const dbRef = ref(db, `Public/${publicCode}/Machine/Stats/Machine/MachineTopicPeriod/${machineId}/Month`);
        onValue(dbRef, (snapshot) => {
            // console.debug("DataMachineStats: onValue: snapshot.val(): ", snapshot.val())
            if (snapshot.val() === null) {
                return
            }
            setDataMachineStats(snapshot.val())
        }, error => {
            console.error(error)
        })
    }, [publicCode, machineId])

    // Fetch DataTopics
    useEffect(() => {
        const db = getDatabase(firebaseApp);
        const dbRef = ref(db, `Machine/MachineTopic`);
        onValue(dbRef, (snapshot) => {
            //console.debug("PublicMachine: onValue: snapshot.val(): ", snapshot.val())
            if (snapshot.val() === null) {
                return
            }
            setDataTopics(snapshot.val())
        }, error => {
            console.error(error)
        })
    }, [publicCode, machineId])

    if (!dataMachine) {
        return <div>Loading...</div>
    }
    // STATE
    let stateHtml = []
    if (dataMachineState && dataTopics) {
        stateHtml.push(
            <table>
                <caption>Actual State</caption>
                <thead>
                <tr>
                    <th>Topic</th>
                    <th>Value</th>
                </tr>
                </thead>
                <tbody>
                {Object.keys(dataMachineState).map((key) => {
                    return (
                        <tr key={"State" + key}>
                            <td>{dataTopics[key].Name}<br/><span
                                className={'secondary-text'}>{core.getFormattedDate(dataMachineState[key]?.Timestamp)}</span>
                            </td>
                            <td className={'value'}>{core.getFormattedValue(dataMachineState[key]?.Value, dataTopics[key])} [{dataTopics[key]?.OutputUnitTitle}]</td>
                        </tr>
                    )
                })
                }
                </tbody>
            </table>
        )

    }

    // STATS
    let statsHtml = []
    if (dataMachineStats && dataTopics) {
        Object.keys(dataMachineStats).forEach((topicKey) => {
            console.debug("dataMachineStats[key]: ", dataMachineStats[topicKey], topicKey)
            let topic = dataTopics[topicKey]
            let topicName = topic?.Name
            let isPower = topic?.Dimension === "Power"

            statsHtml.push(
                <table>
                    <caption>{topicName}</caption>
                    <thead>
                    <tr>
                        <th className={'size-small'}>Month</th>
                        {isPower && <th className={'size-small'}>Energy<br/>[{topic?.OutputUnitTitle}]</th>}
                        {!isPower &&
                            <>
                                <th className={'size-small hide-small'}>Start<br/>[{topic?.OutputUnitTitle}]</th>
                                <th className={'size-small hide-small'}>End<br/>[{topic?.OutputUnitTitle}]</th>
                                <th className={'size-small'}>Delta<br/>[{topic?.OutputUnitTitle}]</th>
                            </>
                        }
                    </tr>
                    </thead>
                    <tbody>
                    {Object.keys(dataMachineStats[topicKey]).map((month) => {
                        if (isPower){
                            return (
                                <tr>
                                    <td className={'size-small'}>{month}</td>
                                    <td className={'value size-small hide-small'}>{core.getFormattedValue(dataMachineStats[topicKey][month]?.Max?.Value, topic)}</td>
                                    <td className={'value size-medium hide-medium secondary-text'}>{core.getFormattedDate(dataMachineStats[topicKey][month]?.Max?.Timestamp)}</td>
                                </tr>
                            )
                        } else {
                            return (
                                <tr>
                                    <td className={'size-small'}>{month}</td>
                                    <td className={'value size-small hide-small'}>{core.getFormattedValue(dataMachineStats[topicKey][month]?.Start?.Value, topic)}</td>
                                    <td className={'value size-small hide-small'}>{core.getFormattedValue(dataMachineStats[topicKey][month]?.End?.Value, topic)}</td>
                                    <td className={'value size-small'}>{core.getFormattedValue(dataMachineStats[topicKey][month]?.Delta?.Value, topic)}</td>
                                    <td className={'value size-medium hide-medium secondary-text'}>{core.getFormattedDate(dataMachineStats[topicKey][month]?.End?.Timestamp)}</td>
                                </tr>
                            )
                        }
                    })
                    }
                    </tbody>
                </table>
            )
        })
    }

    return (
        <div key={machineId & "CodeName"} className={"widget-4x1"}>
            <div className={'status-horizontal'}>
                <div className={"status-info"}>
                    <div className={"title"}>{dataMachine.Name}</div>
                    <div className={"subtitle"}>#{dataMachine.Code}</div>
                    <div className={"subtitle"}>{dataMachine?.Id}</div>
                </div>
            </div>
            {stateHtml}
            {statsHtml}
        </div>
    )
}