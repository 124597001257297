import React, {createContext, useContext, useEffect, useState} from "react";
import {firebaseApp} from "./Connection";
import {
    createUserWithEmailAndPassword,
    getAuth,
    GoogleAuthProvider,
    onAuthStateChanged,
    signInWithEmailAndPassword, signInWithPopup
} from "firebase/auth";

import {getDatabase, onValue, ref} from "firebase/database";

const systemContext = createContext(undefined);

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function SystemProviderRoot({children}) {
    const system = useSystemProvider();
    return <systemContext.Provider value={system}>{children}</systemContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useSystem = () => {
    return useContext(systemContext);
};

// Provider hook that creates auth object and handles state
function useSystemProvider() {

    const noSignInErrors = {
        signIn: null,
        identityExist: null
    };

    // User data registered in Provider
    const [providerUser, setProviderUser] = useState(null);
    const [providerIdentityId, setProviderIdentityId] = useState(null);
    // Identity Id registered in Firebase
    const [identityId, setIdentityId] = useState(null);
    const [errorSignIn, setErrorSignIn] = useState(noSignInErrors)
    // Localization data registered in Firebase
    // Languages - {languages: {cz: "Čeština", en: "English"}}
    // Localized - {country: {cz: "Stát", en: "Country"}}
    const [localization, setLocalization] = useState(null)
    // Preference for active Identity
    let defaultPreference = {
        Language: 'en',
    }
    const [preference, setPreference] = useState(defaultPreference)

    // Sign in with email and password to Firebase
    const signInByEmailPassword = async (email, password) => {
        const auth = getAuth();
        return signInWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                const user = userCredential.user;
                const providerIdentityId = user.uid;
                setProviderUser(user);
                setProviderIdentityId(providerIdentityId);
                return null;
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(`${errorCode} - ${errorMessage}`)
                setProviderIdentityId(null);
                setProviderUser(null);
                setErrorSignIn({
                    ...(error),
                    signIn: errorMessage,
                });
                return error.code;
            });
    };

    const signInByGoogle = async () => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        return signInWithPopup(auth, provider)
            .then((result) => {
                // This gives you a Google Access Token. You can use it to access the Google API.
                console.debug("signInWithPopup", result)
                const credential = GoogleAuthProvider.credentialFromResult(result);
                const token = credential.accessToken;
                // The signed-in user info.
                const user = result.user;
                setProviderUser(user);
                setProviderIdentityId(user.uid);
                return null;
            }).catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(`${errorCode} - ${errorMessage}`)
                setProviderIdentityId(null);
                setProviderUser(null);
                setErrorSignIn({
                    ...(error),
                    signIn: errorMessage,
                });
                return error.code;
            });
    }

    const createIdentityByEmail = async (email, password) => {

        const auth = getAuth();

        return createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in
                return userCredential.user
                // ...
            })
            .catch((error) => {
                const errorCode = error.code;
                const errorMessage = error.message;
                console.error(`${errorCode} - ${errorMessage}`)
                // ..
            });
    };

    // Sign Out from Firebase
    const signOut = async () => {
        const auth = getAuth();
        return auth.signOut()
            .then(() => {
                setProviderUser(null);
                setProviderIdentityId(null);
                setIdentityId(null);
            });
    };

    // Listen to the Firebase Auth state and set the local state.
    useEffect(() => {
        const auth = getAuth()
        onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                // https://firebase.google.com/docs/reference/js/firebase.User
                setProviderUser(user);
                const providerIdentityId = user.uid;
                setProviderIdentityId(providerIdentityId);
                // ...
            } else {
                // User is signed out
                setProviderUser(null)
                setProviderIdentityId(null);
                setIdentityId(null)
                // ...
            }
        });
    }, []);

    // Get the identity profile data from Firebase
    useEffect(() => {

        const firebaseDb = getDatabase(firebaseApp)
        const dbRef = ref(firebaseDb, `User/UserProvider/${providerIdentityId}`)

        const unsubscribe = onValue(dbRef, snapshot => {
            let identity = snapshot.val()
            let identityId = identity?.UserId
            setIdentityId(identityId)
        }, error => {
            console.error(error)
        })

        // Cleanup subscription on unmount
        return () => unsubscribe();
    }, [providerIdentityId]);

    // Get System Localization from Firebase, and set it to state
    useEffect(() => {

        const firebaseDb = getDatabase(firebaseApp)
        const dbRef = ref(firebaseDb, `System/Localization`)

        const unsubscribe = onValue(dbRef, snapshot => {
            setLocalization(snapshot.val())
        }, error => {
            console.error(error)
        })

        // Cleanup subscription on unmount
        return () => unsubscribe();

    }, []);

    // Return available languages from Localization
    // [{code: 'en', name: 'English'}, {code: 'cz', name: 'Čeština'}
    const getAvailableLanguages = () => {

        let output

        if (localization == null) {
            output = [{code: 'en', name: 'English'}]
            return output;
        }

        if (localization.Languages == null) {
            output = [{code: 'en', name: 'English'}]
            return output;
        }

        output = Object.keys(localization.Languages).map((code) => {
            return {
                code: code,
                name: localization.Languages[code]
            }
        })

        return output;
    }

    // Return available languages codes from Localization
    // ['en', 'cz']
    const getAvailableLanguagesCodes = () => {
        return getAvailableLanguages().map((item) => {
            return item.code
        })
    }
    // Return the localized data for the active language
    // getLocalized('Country') => 'Country'
    // If the key is not found, return the key
    function getLocalized(key) {
        return getLocalizedForLanguage(key, preference.Language)
    }

    // Return the localized key for the selected language
    function getLocalizedForLanguage(key, language = 'en') {
        if (localization == null) {
            return key;
        }

        // Localized is not exist
        if (localization.Localized == null) {
            return key;
        }
        // clean key is lowercase, without spaces
        let cleanKey = key.toLowerCase().replace(/ /g, '')

        // get localized data for key
        let localized = localization.Localized[cleanKey]
        if (localized == null) {
            //console.debug('getLocalized', 'Localized is null')
            return key;
        }
        // get localized data for language

        if (localized[language] == null) {
            return key;
        }
        return localized[language];
    }


    function getLocalizedValueForLanguage(value, language = 'en') {
        if (value == null) {
            return null;
        }
        if (value[language] != null) {
            return value[language];
        }
        if (value['en'] != null) {
            return value['en'];
        }
        return null;
    }

    // value is an object with keys for each language
    function getLocalizedValue(value) {
        return getLocalizedValueForLanguage(value, preference.Language)
    }

// Return the user object and auth methods
    return {
        providerIdentityId,
        identityId,
        signInByEmailPassword,
        signInByGoogle,
        createIdentityByEmail,
        signOut,
        errorSignIn,
        preference,
        getAvailableLanguages,
        getAvailableLanguagesCodes,
        getLocalizedForLanguage,
        getLocalized,
        getLocalizedValue,
        getLocalizedValueForLanguage
    };
}

