import moment from "moment/moment";

export function getOutputValue(storedValue, topic) {

    if (storedValue === undefined) {
        return 0
    }

    let storedUnit = topic.StoredInUnit
    let outputUnit = topic.OutputInUnit
    if (storedUnit === outputUnit) {
        return storedValue
    }

    switch (storedUnit) {
        case "Power.W":
            switch (outputUnit) {
                case "Power.kW":
                    return storedValue * 1e-3
                default:
                    return storedValue
            }
        case "Energy.Wh":
            switch (outputUnit) {
                case "Energy.kWh":
                    return storedValue * 1e-3
                default:
                    return storedValue
            }
            case "Energy.J":
            switch (outputUnit) {
                case "Energy.GJ":
                    return storedValue * 1e-9
                default:
                    return storedValue
            }
        default:
            return storedValue
    }

}

export function machineTopic(topicId, value, topicsDefinition) {

    if (topicsDefinition === undefined) {
        return {topicName: topicId, topicValue: value, topicUnit: "-", topicFormatted: value.toLocaleString()}
    }

    let topicsKeys = Object.keys(topicsDefinition)
    if (!topicsKeys.includes(topicId)) {
        return {topicName: topicId, topicValue: value, topicUnit: "-", topicFormatted: value.toLocaleString()}
    }

    let topic = topicsDefinition[topicId]
    let topicUnit = topic?.OutputUnitTitle ?? "-"
    let topicValue = getOutputValue(value, topic)
    let topicFormatted = `${topicValue.toLocaleString(undefined, {
        maximumFractionDigits: topic.OutputDecimal,
        minimumFractionDigits: topic.OutputDecimal
    })} ${topicUnit}`
    return {topicName: topic.Name, topicValue: topicValue, topicUnit: topicUnit, topicFormatted: topicFormatted}
}

export function getMachineTopic(topicId, topicsDefinition) {

    if (topicsDefinition === undefined) {
        return null
    }

    let topicsKeys = Object.keys(topicsDefinition)
    if (!topicsKeys.includes(topicId)) {
        return null
    }

    return topicsDefinition[topicId]
}

export function getFormattedValue(value, topic) {
    if (topic) {
        //console.debug("getFormattedValue", value, topic)
        let topicValue = getOutputValue(value, topic)
        let topicFormatted = `${topicValue.toLocaleString(undefined, {
            maximumFractionDigits: topic.OutputDecimal,
            minimumFractionDigits: topic.OutputDecimal
        })}`
        return topicFormatted
    } else {
        return value
    }
}

export function getFormattedDate(timestamp) {
    if (timestamp === undefined) {
        return null
    }
    let date = new Date(timestamp)
    return moment(date).format('YYYY-MM-DD HH:mm:ss')
}