import React from 'react';
import {Public} from "./Public";
import {PrivateZone} from "./PrivateZone";
import {useSystem} from "./Service/UseSystem";
import {SignIn} from "./SignIn";

function App() {

    let url = new URL(window.location.href)
    let publicCode = url.pathname.split("/")[1]
    document.title = 'machine'
    let system = useSystem();

    const [showSignIn, setShowSignIn] = React.useState(false);

    console.debug("system", system.providerIdentityId, system.identityId)
    if (system.identityId) {
        return <PrivateZone/>
    }

    if (showSignIn) {
        return (
            <div className={'widget-2x1'}>
                <SignIn setShowSignIn={setShowSignIn}/>
            </div>)
    }

    let html = []

    if (publicCode === undefined || publicCode === "") {
        html.push(<p key={'not-defined'}>machine is not defined ...</p>)
        html.push(<p key={'not-defined1'}>please enter a machine code in the url</p>)
        html.push(<footer key={'not-defined2'} className={'action'} onClick={() => {
            setShowSignIn(true)
        }}>[private zone for VIP]</footer>)
    } else {
        html.push(<Public publicCode={publicCode}/>)
    }

    return html
}

export default App;
