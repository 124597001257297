import React, {useEffect, useState} from "react";
//import {PublicMachine} from "./PublicMachine";
import {firebaseApp} from "./Service/Connection";
import {getDatabase, onValue, ref} from "firebase/database";
import {PublicMachine} from "./PublicMachine";


export function Public({publicCode}) {

    const [sorted, setSorted] = useState([]);
    const [filtered, setFiltered] = useState([]);
    const [search, setSearch] = useState("");

    useEffect(() => {
        if (publicCode === undefined || publicCode === "") {
            return;
        }

        let path = `Public/${publicCode}/Machine/Machine`
        let db = getDatabase(firebaseApp);
        let dbRef = ref(db, path);
        onValue(dbRef, (snapshot) => {

            if (snapshot.val()) {
                let d = snapshot.val()
                // add Id
                for (let key in d) {
                    d[key].Id = key
                }
                // Sort by Code
                let s = Object.values(d).sort((a, b) => {
                        return a.Code.localeCompare(b.Code)
                    }
                )
                setSorted(s)
            }
        }, error => {
            console.error(error)
        })
    }, [publicCode])

    useEffect(() => {
        console.debug("search: ", search)
        if (search === "") {
            console.debug("search empty")
            setFiltered(sorted)
            return
        }
        let f = sorted.filter(m => {
            return m.Code.toLowerCase().includes(search.toLowerCase())
        })
        setFiltered(f)
    }, [search, sorted])

    if (sorted.length === 0) {
        return <div>sorry, nothing ...</div>
    }

    let machinesHtml = []
    for (let i = 0; i < filtered.length; i++) {
        let m = filtered[i]
        machinesHtml.push(<PublicMachine key={m.Id} publicCode={publicCode} machineId={m.Id}/>)
    }

    return (
        <div>
            <div className={'actions'}>
                <input type="text" placeholder="Search..." onChange={e => setSearch(e.target.value)}/>
                <div className={'action'} onClick={() => {
                    setSearch("")
                }}>x</div>
            </div>
            <div className={'mainContent'}>
                {machinesHtml}
            </div>
        </div>
    )
}