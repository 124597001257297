import React, {useEffect} from "react";
import {useSystem} from "./Service/UseSystem";
import {getDatabase, onValue, ref, set} from "firebase/database";
import {firebaseApp} from "./Service/Connection";
import {getFormattedValue} from "./core";
import * as core from "./core";
import moment from "moment/moment";


export function PrivateZone() {

    let system = useSystem()
    const [machines, setMachines] = React.useState(null)
    const [topics, setTopics] = React.useState(null)
    const [machineStates, setMachineStates] = React.useState(null)
    const [newValuePeriod, setNewValuePeriod] = React.useState("")

    useEffect(() => {
        let path = `/Machine/Machine`
        let db = getDatabase(firebaseApp);
        let dbRef = ref(db, path);
        onValue(dbRef, (snapshot) => {
            const data = snapshot.val();
            setMachines(data);
        }, error => {
            console.error(error)
        })
    }, []);

    useEffect(() => {
        let path = `/Machine/MachineTopic`
        let db = getDatabase(firebaseApp);
        let dbRef = ref(db, path);
        onValue(dbRef, (snapshot) => {
            const data = snapshot.val();
            setTopics(data);
        }, error => {
            console.error(error)
        })
    }, []);

    useEffect(() => {
        let path = `/Machine/State/Machine/State`
        let db = getDatabase(firebaseApp);
        let dbRef = ref(db, path);
        onValue(dbRef, (snapshot) => {
            const data = snapshot.val();
            setMachineStates(data);
        }, error => {
            console.error(error)
        })
    }, []);

    let html = []

    if (!machines) {
        html.push(<div>Loading machines...</div>)
    }
    if (!topics) {
        html.push(<div>Loading topics...</div>)
    }
    if (!machineStates) {
        html.push(<div>Loading machineStates...</div>)
    }

    function MachinesTopicsStates({machineId}) {
        console.debug(`MachinesTopicsStates`, machineId)
        let machine = machines[machineId]
        let machineState = machineStates[machineId]
        return (
            <tr>
                <td>{machine.Name}</td>
                {Object.keys(machineState).map((topicId) => {
                    let topic = topics[topicId]
                    //let value = machineState[topicId]?.Value
                    // let formattedValue = getFormattedValue(value, topic)
                    // let formattedDate = core.getFormattedDate(machineState[topicId]?.Timestamp)
                    return <TopicStateCell machine={machine}
                                           machineId={machineId}
                                           topic={topic}
                                           topicId={topicId}
                                           state={machineState[topicId]}
                                           period={newValuePeriod}
                    />
                })}
            </tr>
        )
    }

    function MachineTopicsStates({states}) {
        return (<>
            {Object.keys(states).map((machineId) => {
                let machine = machines[machineId]
                return <MachinesTopicsStates machineId={machineId}/>
            })}
        </>)
    }

    if (machines && topics && machineStates) {
        // Table
        html.push(<table className={'alternate'}>
            <thead>
            <tr>
                <th>Machine</th>
                <th>
                    <div className={'action'} onClick={() => {
                        const newValue = prompt(`Change value period`, newValuePeriod)
                        setNewValuePeriod(newValue)
                    }}>Change value period [{newValuePeriod}]
                    </div>
                </th>
            </tr>
            </thead>
            <tbody>
            <MachineTopicsStates states={machineStates}/>
            </tbody>
        </table>)
    }

    return (
        <div className={"mainContent"}>
            <div className={"view"}>
                {html}
            </div>
            <footer className={'action'} onClick={system.signOut}>SingOut</footer>
        </div>
    )
}

function TopicStateCell({machine, machineId, topic, topicId, state, period}) {

    let system = useSystem()

    const [showEdit, setShowEdit] = React.useState(false)
    const [oldValue, setOldValue] = React.useState(state?.Value)
    const [value, setValue] = React.useState(state?.Value)
    const [timestamp, setTimestamp] = React.useState(state?.Timestamp)

    const EmptyStatePayload = {
        Sender: {
            Id: system.identityId,
            Timestamp: new Date().getTime(),
            Type: "User"
        },
        Timestamp: new Date().getTime(),
        Value: 0,
    }

    // Period: YYYY-MM, end of Month
    function timestampFromPeriod(period) {
        let parts = period.split("-")
        let year = parseInt(parts[0])
        let month = parseInt(parts[1])
        let date = new Date(year, month, 0)
        return date.getTime()
    }

    if (showEdit) {
        return (
            <td>
                <div>Period: {period}</div>
                <div>{core.getFormattedDate(timestampFromPeriod(period))}</div>
                <div>Old: {core.getFormattedValue(oldValue, topic)} {topic?.OutputUnitTitle}</div>
                <div>New: {core.getFormattedValue(value, topic)} {topic?.OutputUnitTitle}</div>
                <input type={"number"} value={value} onChange={(e) => {
                    setValue(e.target.value)
                }}/>
                <div className={'actions'}>
                    <div className={'action'} onClick={() => {

                        let path = `/Machine/State/Machine/State/${machineId}/${topicId}`
                        let paylod = EmptyStatePayload
                        paylod.Value = parseFloat(value)
                        paylod.Timestamp = timestampFromPeriod(period)
                        console.debug(`set`, path, paylod)

                        let db = getDatabase(firebaseApp);
                        let dbRef = ref(db, path);
                        set(dbRef, paylod)
                        setShowEdit(false)
                    }
                    }>save
                    </div>
                    <div className={'action'} onClick={() => {
                        setShowEdit(false)
                    }
                    }>cancel
                    </div>
                </div>
            </td>
        )
    }

    return (
        <td
            className={'size-medium'}>{topic?.Name}<br/>{core.getFormattedValue(value, topic)} [{topic?.OutputUnitTitle}]<br/>
            <span className={'secondary-text'}>{core.getFormattedDate(timestamp)}</span><br/>
            <div className={'action'} onClick={() => {
                setShowEdit(true)
            }
            }>Edit
            </div>
        </td>
    )
}