import React, {useState} from "react";
import {useSystem} from "./Service/UseSystem";
import {GoogleAuthProvider, signInWithPopup} from "firebase/auth";

export function SignIn() {

    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [signInError, setSignInError] = useState(null)

    const system = useSystem()
    const provider = new GoogleAuthProvider();
    const handleSignInGoogle = async () => {
        console.log("handleSignInGoogle")
        system.signInByGoogle().then((r) => {
            console.debug("handleSignInGoogle", r)
        }
        )
    }

    // SignIn
    const handleSignIn = async () => {
        // Check email, password
        if (email === "" || password === "") {
            return
        }

        // Login
        return system.signInByEmailPassword(email, password).then((r) => {
            if (r.error) {
                // inform User, that login failed or User is not registered
                setSignInError("Login failed. Please check your email and password.")
            }

        })
    }

    return (<form>
            <div>
                <div className={'view-title'}>p.d.a. system</div>
                <div className={'view-subtitle'}>personal digital assistant</div>
            </div>
            <div className={'form-field'}>
                <label>
                    Email
                </label>
                <input key="email"
                       id="email"
                       type={"text"}
                       autoComplete={'username'}
                       placeholder="email"
                       onChange={async e => {
                           setSignInError(null)
                           setEmail(e.target.value)
                       }}
                />
            </div>

            <div className={'form-field'}>
                <label>
                    Password
                </label>
                <input key="password"
                       id="password"
                       type={"password"}
                       autoComplete={'current-password'}
                       ng-hide="true"
                       placeholder="password"
                       onChange={async e => {
                           setSignInError(null)
                           setPassword(e.target.value)
                       }}
                       onKeyUp={async e => {
                           if (e.key === 'Enter') {
                               await handleSignIn()
                           }
                       }}
                />
            </div>

            <button type="button"
                    onClick={() => handleSignIn()}>
                sign in
            </button>
            <div className={'action'} onClick={handleSignInGoogle}>Google</div>

            {signInError && <div className={'error'}>{signInError}</div>}

        </form>

    )
}